import type { GadgetError } from "@gadgetinc/errors";
import { compact } from "lodash";
import type { FieldType } from "./type-system/FieldType";

export enum RichStringTagType {
  /** Can be displayed as a special tag */
  RichText = "RichText",
  /** Should be displayed as regular body text */
  PlainText = "PlainText",
  Relationship = "Relationship",
  FieldType = "FieldType",
  File = "File",
  Validation = "Validation",
  /** Models refer to dynamic data, and should be replaced with a live model name */
  Model = "Model",
}

type BaseTagType = Exclude<RichStringTagType, RichStringTagType.PlainText | RichStringTagType.FieldType | RichStringTagType.Model>;
export interface PlainTextTag {
  text: string;
  type: RichStringTagType.PlainText;
}

export interface BaseTag {
  text: string;
  type: BaseTagType;
}

export interface FieldTypeTag {
  text: string;
  type: RichStringTagType.FieldType;
  displayText?: string;
}
export interface ModelTag {
  text: string;
  type: RichStringTagType.Model;
  prefix?: string;
  suffix?: string;
}

export type RichStringTag = PlainTextTag | BaseTag | FieldTypeTag | ModelTag;

class RichStringBuilder {
  private divider = "::";
  private openingTag = "$[[";
  private closingTag = "]]$";

  /** Produces a tag of type `PlainTextTag` */
  private getTextTagByType(type: RichStringTagType, tagPrefix = `"`, tagSuffix = `"`, ...content: string[]): PlainTextTag {
    const [text, displayText] = content;
    switch (type) {
      case RichStringTagType.FieldType:
        return { type: RichStringTagType.PlainText, text: `${tagPrefix}${displayText ?? text}${tagSuffix}` } as PlainTextTag;
      default:
        return { type: RichStringTagType.PlainText, text: `${tagPrefix}${text}${tagSuffix}` } as PlainTextTag;
    }
  }

  /** Produces a tag based on the type (`RichStringTagType`) being passed in */
  private getTagByType(type: RichStringTagType, ...content: string[]): RichStringTag {
    const [text, ...params] = content;
    switch (type) {
      case RichStringTagType.FieldType:
        return {
          type,
          text,
          ...(params && params.length > 0 ? { displayText: params[0] } : {}),
        } as FieldTypeTag;
      case RichStringTagType.Model:
        return {
          type,
          text,
          prefix: params[0],
          suffix: params[1],
        } as ModelTag;
      default:
        return { type, text } as BaseTag;
    }
  }

  /** Internal method to actually build the encoded tag */
  private buildTag(type: RichStringTagType, ...entries: Array<string | undefined>) {
    const content = `${type}${this.divider}${compact(entries).join(this.divider)}`;
    return `${this.openingTag}${content}${this.closingTag}`;
  }

  /** Encode a basic tag from text content, used to visually differentiate important text */
  text(text: string): string {
    return this.buildTag(RichStringTagType.RichText, text);
  }

  /** Encode a file tag, used to highlight a file path and file type */
  file(filePath: string): string {
    return this.buildTag(RichStringTagType.File, filePath);
  }

  /** Encode a validation tag, used to highlight the kind of validation */
  validation(name: string): string {
    return this.buildTag(RichStringTagType.Validation, name);
  }

  /** Encode a field type tag, used for color coding */
  fieldType(type: FieldType, displayName?: string): string {
    return this.buildTag(RichStringTagType.FieldType, type, displayName);
  }

  /** Encode a relationship tag, used to highlight model relationships */
  relationship(text: string): string {
    return this.buildTag(RichStringTagType.Relationship, text);
  }

  /** Encode a model tag, used to highlight model models */
  model(fallbackText: string, prefix?: string, suffix?: string): string {
    return this.buildTag(RichStringTagType.Model, fallbackText, prefix, suffix);
  }

  /** Decode a string (possibly) containing encoded tags */
  parse(taggedMessage: string, outputTextOnly = false, tagPrefix = `"`, tagSuffix = `"`): RichStringTag[] {
    const dynamicMessage: RichStringTag[] = [];
    const addPart = (p: RichStringTag) => p.text.length > 0 && dynamicMessage.push(p);
    const messageAsString =
      typeof taggedMessage !== "string"
        ? "Unknown error" // In the event that this is not a string, we can't know what the error message is
        : taggedMessage;

    const messageParts = messageAsString.split(this.closingTag);

    if (messageParts.length === 1) {
      addPart({ type: RichStringTagType.PlainText, text: messageParts[0] });
    } else {
      messageParts.forEach((part: string, index: number) => {
        const getSuffix = (c?: string) => (!c && index < messageParts.length - 1 ? this.closingTag : "");
        const dynamicParts = part.split(this.openingTag);
        let tagContent: string | undefined;

        // We have one or more orphaned opening tags
        if (dynamicParts.length > 2) {
          // Just use the last part as a tag, since that's the closest one to the closing tag
          tagContent = dynamicParts.pop();
          // And keep the rest as it was originally
          const stringContent = dynamicParts.join(this.openingTag);
          addPart({ type: RichStringTagType.PlainText, text: `${stringContent}${getSuffix(tagContent)}` });
        } else {
          tagContent = dynamicParts[1];
          addPart({ type: RichStringTagType.PlainText, text: `${dynamicParts[0]}${getSuffix(tagContent)}` });
        }

        if (tagContent) {
          const tagParts = tagContent.split(this.divider);
          let isValidTag = false;
          if (tagParts.length > 1) {
            const [typeKey, ...content] = tagParts;
            // The first part is the tag type; the second is the text content
            const type = RichStringTagType[typeKey as keyof typeof RichStringTagType];
            if (type) {
              isValidTag = true;
              const tagOutput = outputTextOnly
                ? this.getTextTagByType(type, tagPrefix, tagSuffix, ...content)
                : this.getTagByType(type, ...content);
              addPart(tagOutput);
            }
          }
          if (!isValidTag) {
            // This isn't a valid tag, with a type, so just push it as text
            addPart({ type: RichStringTagType.PlainText, text: `${this.openingTag}${tagParts.join(this.divider)}${this.closingTag}` });
          }
        }
      });
    }

    return dynamicMessage;
  }

  /** Decode a string (possibly) containing encoded tags, and return it as a tag-less string */
  parseToString(taggedMessage: string, tagPrefix = `"`, tagSuffix = `"`): string {
    return this.parse(taggedMessage, true, tagPrefix, tagSuffix)
      .map(({ text }) => text)
      .join("");
  }

  /** Parse a possible error object, which can be an array of tagged messages */
  parseError<T extends GadgetError | Record<string, any>>(error: T): T {
    const message = typeof error.message === "string" ? this.parseToString(error.message) : error.message;
    const details = Array.isArray(error.details)
      ? error.details.map((detail: any) => (typeof detail === "string" ? this.parseToString(detail) : detail))
      : error.details;

    const parsedError: T = { ...error };
    if (message) {
      parsedError.message = message;
    }
    if (details) {
      parsedError.details = details;
    }
    return parsedError;
  }
}

/** String tag encoder/decoder, for building dynamic content */
export const RichString = new RichStringBuilder();
