export enum ProblemSeverity {
  Fatal = "Fatal",
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
}

export const numericSeverity = (severity: ProblemSeverity) => {
  if (severity == ProblemSeverity.Fatal) {
    return 4;
  } else if (severity == ProblemSeverity.Error) {
    return 3;
  } else if (severity == ProblemSeverity.Warning) {
    return 2;
  } else {
    return 1;
  }
};

const ERROR_SEVERITY_VALUE = numericSeverity(ProblemSeverity.Error);

export const problemSeverityIsErrorOrAbove = (severity?: ProblemSeverity): boolean =>
  severity ? numericSeverity(severity) >= ERROR_SEVERITY_VALUE : false;
