import { styled, useStyletron } from "baseui";
import { omit } from "lodash";
import React from "react";

export const ListItem = styled("li", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale800,
  ...$theme.typography.ParagraphMedium,
  gridColumn: 1,
}));

export const UnorderedList = styled("ul", ({ $theme }) => ({
  ...$theme.typography.ParagraphMedium,
  listStyleType: "circle",
  marginBottom: $theme.sizing.scale600,
  gridColumn: 1,
}));

export const OrderedList = (props: JSX.IntrinsicElements["ol"]) => {
  const [css, $theme] = useStyletron();
  return (
    <ol
      {...omit(props, ["ordered"])}
      className={css({
        ...$theme.typography.ParagraphMedium,
        marginBottom: $theme.sizing.scale600,
        gridColumn: 1,
      })}
    />
  );
};
